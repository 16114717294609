const IndexBanner = () => {
  return (
    <section className="index_banner">
      <div className="index_banner_content">
        <div className="index_banner_con">
          <div className="index_banner_titcon">
            <h5 className="index_banner_tit">
              <span>
                O<span className="tit_orange">V</span>O
              </span>{" "}
              Platform
            </h5>
          </div>
          <p>
            ovo (ovo space) is the industry's first platform to issue
            holographic AR-NFT assets and is currently deployed on the BSC and
            FLOW. The NFT issued by ovo will be delivered as Super Avatars to
            various Metaverses and GameFi platforms.
          </p>
          {/* <div className="button_banner_con">
            <a href="https://ovobsc.ovo.space/#/" target="_blank" className="button_banner" type="button">ENTER WEBSITE ON BSC</a>
            <a href="https://ovoeth.ovo.space/#/" target="_blank" className="button_banner" type="button">ENTER WEBSITE ON FLUcTUS</a>
          </div>
          <div className="button_banner_con">
            <a href="https://ovoflow.ovo.space/#/" target="_blank" className="button_banner" type="button">ENTER WEBSITE ON FLOW</a>
          </div> */}
          <div className="dropdown_button_con">
            <div className="dropdown_button">
              <div className="dropdown_self">GO TO WEBSITE</div>
              <div className="dropdown_content">
                <div className="dropdown_con">
                  <a
                    href="https://ovobsc.ovo.space/#/"
                    target="_blank"
                    type="button"
                  >
                    BSC
                  </a>
                  {/* <a href="https://ovoeth.ovo.space/#/" target="_blank" type="button">ETH</a>
                  <a href="https://ovoflow.ovo.space/#/" target="_blank" type="button">FLOW</a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="sign_list">
            <a
              className="sign_li"
              href="https://twitter.com/ovo__official"
              target="_blank"
            >
              <i className="sign_icon0"></i>
            </a>
            <a
              className="sign_li"
              href="http://discord.gg/ovonft"
              target="_blank"
            >
              <i className="sign_icon1"></i>
            </a>
            <a
              className="sign_li"
              href="https://ovo-nft-platform.gitbook.io/ovo-platform/v/new-whitepaper/"
              target="_blank"
            >
              <i className="sign_icon2"></i>
            </a>
            <a
              className="sign_li"
              href=" https://medium.com/@ovonft"
              target="_blank"
            >
              <i className="sign_icon3"></i>
            </a>
          </div>
        </div>
        <div className="infor_icon_con infor_icon_con0">
          <i className="infor_icon infor_icon0"></i>
        </div>
        <div className="infor_icon_con infor_icon_con1 infor_icon_conpc">
          <i className="infor_icon infor_icon1"></i>
        </div>
        <div className="infor_icon_con infor_icon_con2 infor_icon_conpc">
          <i className="infor_icon infor_icon2"></i>
        </div>
        <div className="index_portrait_img portrait_img_con"></div>
      </div>
      <div className="button_banner_pcon">
        <p className="button_banner_p">
          ovo (ovo space) is the industry's frst platform to issue holographic
          AR-NFT assets and is currently deployed on the BSC and FLOW. The NFT
          issued by ovo will be delivered as Super Avatars to various Metaverses
          and GameFi platforms.
        </p>
        <div className="infor_icon_con infor_icon_con1 infor_icon_conm">
          <i className="infor_icon infor_icon1"></i>
        </div>
        <div className="infor_icon_con infor_icon_con2 infor_icon_conm">
          <i className="infor_icon infor_icon2"></i>
        </div>
      </div>
      <div className="index_portrait_img portrait_img_set"></div>
      <div className="big_data_con"></div>
    </section>
  );
};
export default IndexBanner;
