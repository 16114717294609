import IndexBanner from './components/indexBanner'
import Dcim from './components/dcim'
import Nyathees from './components/nyathees'
import JigenToys from './components/jigenToys'
import Metaverse from './components/metaverse'
import Roadmap from './components/roadmap'
import Partner from './components/partner'
import { useState,useEffect } from 'react';
import './index.scss'
const Index = ({})=>{
  const [scrollAni0,setScrollAni0] = useState(true)
  const [scrollAni1,setScrollAni1] = useState(true)
  const [scrollAni2,setScrollAni2] = useState(true)
  // useEffect(()=>{},[
  //   scrollTopHand()
  // ])
  
  let times;
  let m = 0
  let n =0
  const scrollTopHand = ()=>{
    let scrolltop=null;
    let docWidth = document.body.offsetWidth
    scrolltop=document.getElementById("parallaxB").scrollTop;
    let dcimSecTop = document.getElementById("DcimSec")
    let nyatheesTop=document.getElementById("NyatheesSection");
    let JigenToysCon = document.getElementById("JigenToysCon")
    let MetaverseCon = document.getElementById("MetaverseCon")
    let RoadmapCon = document.getElementById("RoadmapCon")
    let PartnerCon = document.getElementById("PartnerCon")
    let NavListF = document.getElementById("NavListF").getElementsByTagName('li')
    if(scrolltop > 80){
      document.getElementById("NavListF").style.top="0px";
      document.getElementById("NavList").style.opacity="0%";
    }else{
      document.getElementById("NavListF").style.top="-80px";
      document.getElementById("NavList").style.opacity="100%";
    }
    NavListF[0].className = ""
    NavListF[1].className = ""
    NavListF[2].className = ""
    NavListF[3].className = ""
    NavListF[4].className = ""
    NavListF[5].className = ""
    NavListF[6].className = ""
    if(scrolltop>=dcimSecTop.offsetTop-200&&scrolltop<nyatheesTop.offsetTop){
      NavListF[0].className = "active"
    }else if(scrolltop>=nyatheesTop.offsetTop-200&&scrolltop<JigenToysCon.offsetTop) {
      NavListF[1].className = "active"
    }else if(scrolltop>=JigenToysCon.offsetTop-200&&scrolltop<MetaverseCon.offsetTop) {
      NavListF[2].className = "active"
    }else if(scrolltop>=MetaverseCon.offsetTop-200&&scrolltop<RoadmapCon.offsetTop) {
      NavListF[4].className = "active"
    }else if(scrolltop>=RoadmapCon.offsetTop-200&&scrolltop<PartnerCon.offsetTop) {
      NavListF[5].className = "active"
    }else if(scrolltop>=PartnerCon.offsetTop-200) {
      NavListF[6].className = "active"
    }
    if(docWidth>1100){
      if(scrolltop>=nyatheesTop.offsetTop-dcimSecTop.offsetHeight/2+50 && scrollAni0){
        clearInterval(times)
        animationOpctity()
        setScrollAni0(false)
        document.getElementById("NyatheesSection").className = "nyathees_section nyathees_transform"
        document.getElementById("jigenDecorateBg").style.display = "block"
      }
    } else {
      if(scrolltop>=nyatheesTop.offsetTop-dcimSecTop.offsetHeight+50 && scrollAni0){
        clearInterval(times)
        setScrollAni0(false)
        document.getElementById("NyatheesSection").className = "nyathees_section nyathees_transform"
        document.getElementById("jigenDecorateBg").style.display = "block"
      }
    }
    if(scrolltop>=JigenToysCon.offsetTop-nyatheesTop.offsetHeight/2+50 && scrollAni1){
      document.getElementById("JigenToysCon").className = "jigen_toys_section jigen_toys_animation"
      setScrollAni1(false)
    }
    if(scrolltop>=MetaverseCon.offsetTop-JigenToysCon.offsetHeight/2+50 && scrollAni2){
      document.getElementById("MetaverseCon").className = "metaverse_section metaverse_animation"
      setScrollAni2(false)
    }
  }
  //轮播
  const animationOpctity = ()=>{
    let NyatheesFr = document.getElementById("NyatheesFr")
    let imgCon = document.getElementById("NyatheesFr").getElementsByTagName('img')
    let nyatheesFl = document.getElementById("nyatheesFl")
    let NyatheesTextCon = document.getElementById("NyatheesTextCon").getElementsByClassName('nyathees_text_con')
    n=imgCon.length;
    NyatheesTextCon[0].style.opacity = 1
    let timesIn = ()=>{
      times= setInterval(()=>{
        imgCon[0].className = 'nyathees_img'
        imgCon[1].className = 'nyathees_img'
        imgCon[2].className = 'nyathees_img'
        NyatheesTextCon[0].style.opacity = 0
        NyatheesTextCon[1].style.opacity = 0
        NyatheesTextCon[2].style.opacity = 0
        m++
        if(m>n-1){
          m=0
        }
        NyatheesTextCon[m].style.opacity = 1
        imgCon[m].className = 'nyathees_img nyathees_img_animation'
      },2800)
    }
    timesIn()
    nyatheesFl.onmousemove = ()=>{
      clearInterval(times)
    }
    nyatheesFl.onmouseout = ()=>{
      clearInterval(times)
      timesIn()
    }
    NyatheesFr.onmousemove = ()=>{
      clearInterval(times)
    }
    NyatheesFr.onmouseout = ()=>{
      clearInterval(times)
      timesIn()
    }
  }
  const changeN = (type)=>{
    let imgCon = document.getElementById("NyatheesFr").getElementsByTagName('img')
    let NyatheesTextCon = document.getElementById("NyatheesTextCon").getElementsByClassName('nyathees_text_con')
    n=imgCon.length;
    imgCon[0].className = 'nyathees_img'
    imgCon[1].className = 'nyathees_img'
    imgCon[2].className = 'nyathees_img'
    NyatheesTextCon[0].style.opacity = 0
    NyatheesTextCon[1].style.opacity = 0
    NyatheesTextCon[2].style.opacity = 0
    if(type==='0'){
      m++
      if(m>n-1){
        m=0
      }
    }else{
      m--
      if(m<0){
        m=n-1
      }
    }
    NyatheesTextCon[m].style.opacity = 1
    imgCon[m].className = 'nyathees_img nyathees_img_animation'
  }
  return (
    <div className="index_content" id="parallaxB" onScrollCapture={() => scrollTopHand()}>
      <IndexBanner />
      <Dcim />
      <Nyathees changeNs={changeN}/>
      <JigenToys />
      <Metaverse />
      <Roadmap />
      <Partner />
    </div>
  )
}
export default Index