import {
  BrowserRouter,
  useRoutes
} from "react-router-dom";
import Index from "./pages/Index/index";
import LayoutItem from "./layout/index"
import TokenInfo from "./pages/Index/components/tokenInfo";
const AppRoutes = () => {
  let routes = useRoutes([
    { path: "Index", element: <Index /> },
    { path: "/tokeninfo", element: <TokenInfo /> },
    { path: "/",element: <Index />},
  ]);
  return routes;
}
function App() {
  return (
    <BrowserRouter>
      <LayoutItem AppRoutes={AppRoutes}></LayoutItem>
    </BrowserRouter>
  );
}

export default App;
