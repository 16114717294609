import Header from "./components/header"
import "./assets/css/index.scss"
import { useState,useEffect } from 'react';
import {
  Link,useLocation
} from "react-router-dom";
const LayoutTem = ({AppRoutes})=>{
  useEffect(()=>{
  },[])
  return(
    <div className="content_body">
      <Header></Header>
      <AppRoutes/>
    </div>
  )
}
export default LayoutTem