import dcimFlow from "../../../assets/images/index/dcim_flow.png"
const Dcim = ()=>{
  return (
    <section className="dcim_section" id="DcimSec">
      <div className="dcim_title">
        <div className="dcim_title_con">
          <h5 className="dcim_h5">DCIM</h5>
          <div className="dcim_sub">introduction</div>  
        </div>
      </div>
      <div className="dcim_content">
        <p className="dcim_content_p">OVO (The Only Value Observation) pioneered the virtual asset right authentication scheme, that is, the dual copyright inspection mechanism(DCIM). This is composed of the lower-level copyright verification and the upper-level asset management mechanism. 
The lowerlevel is the copyright-related cooperation and authorization agreement represented by NFT, and the upper-level is the issuance 
and management circulation mechanism of those NFT assets. NFT is based on ERC721 and ERC1155.</p>
        <div className="dcim_content_img"><img src={dcimFlow} alt="" /></div>
      </div>
    </section>
  )
}
export default Dcim