const Metaverse = ()=>{
  return (
    <section className="metaverse_section" id="MetaverseCon">
      <div className="metaverse_con">
        <div className="metaverse_fr">
          <div className="metaverse_fr_head">
            <i className="metaverse_icon"></i>
            <h5 className="metaverse_tit"><span>MET</span>AVERSE</h5>
          </div>
          <div className="metaverse_fr_con">
            <p>In the near future, the NFT assets issued on OVO will be processed and transferred to any metaverse platform that is connected to the OVO platform. For example, the famous Japanese two-dimensional character "Nyathees" published on the OVO platform. Users can purchase "Nyathees" NFT card, in addition to basic functions such as NFT transaction and mining. Users can also use it in other metaverse platforms (such as Decentraland, SandBox, MatrixWorld and etc.).</p>
            <p>Eventually, OVO will build an immersive creation system based on XR (AR, VR, MR) technology. Any creator will be able to create NFT in the virtual space built by OVO and distribute them to various metaverse platforms.</p>
          </div>
        </div>
        <div className="metaverse_fl">
          <img className="metaverse_fl_img metaverse_m_img" src={require("../../../assets/images/mobileIndex/metaverse_ls.png")} />
          <img className="metaverse_fl_img metaverse_pc_img" src={require("../../../assets/images/index/metaverse_ls.png")} />
          <div className="metaverse_block_ls">
            <div className="metaverse_block metaverse_block0"></div>
            <div className="metaverse_block metaverse_block1"></div>
            <div className="metaverse_block metaverse_block2"></div>
            <div className="metaverse_block metaverse_block3"></div>
            <div className="metaverse_block metaverse_block4"></div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Metaverse