import { useState, useEffect } from "react";
// import img0 from '../../../assets/images/index/partner0.png'
// import img1 from '../../../assets/images/index/partner1.png'
// import img2 from '../../../assets/images/index/partner2.png'
// import img3 from '../../../assets/images/index/partner3.png'
// import img4 from '../../../assets/images/index/partner4.png'
// import img5 from '../../../assets/images/index/partner5.png'
// import img6 from '../../../assets/images/index/partner6.png'
// import img7 from '../../../assets/images/index/partner7.png'
// import img8 from '../../../assets/images/index/partner8.png'
// import img9 from '../../../assets/images/index/partner9.png'
// import img10 from '../../../assets/images/index/partner10.png'
// import img11 from '../../../assets/images/index/partner11.png'
// import img12 from '../../../assets/images/index/partner12.png'
// import img13 from '../../../assets/images/index/partner13.png'
// import img14 from '../../../assets/images/index/partner14.png'
// import img15 from '../../../assets/images/index/partner15.png'
// import img16 from '../../../assets/images/index/partner16.png'
// import img17 from '../../../assets/images/index/partner17.png'
// import img18 from '../../../assets/images/index/partner18.png'
// import img19 from '../../../assets/images/index/partner19.png'
const Partner = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    listData();
  }, []);
  const listData = () => {
    let arr = [];
    for (let i = 0; i < 21; i++) {
      arr.push({
        name: "",
        imgUrl: "",
        link: "",
      });
    }
    setData(arr);
  };
  return (
    <section className="partner_section" id="PartnerCon">
      <div className="partner_content">
        <h5 className="partner_tit">Partner</h5>
        <div className="partner_list">
          {data.map((val, index) => {
            return (
              <a href="#">
                <img
                  src={require("../../../assets/images/index/partner" +
                    index +
                    ".png")}
                />
              </a>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default Partner;
