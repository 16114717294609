import jigenToysImg from "../../../assets/images/index/jigentoys_img.png"
import playImg from "../../../assets/images/index/play_btn.svg"
import { useState,useEffect } from 'react';
const JigenToys = ()=>{
  // const play = ()=>{
  //   let myVideo = document.getElementById("myVideo")
  //   // myVideo.play()
  //   myVideo.addEventListener('play',()=>{
  //     console.log("开始播放");
  //     document.getElementById("jigenToysBan").style.display = "none"
  //   });
  // }
  const playBtn = ()=>{
    let myVideo = document.getElementById("myVideo")
    myVideo.style.opacity="1"
    myVideo.play()
    document.getElementById("jigenToysBan").style.display = "none"
  }
  useEffect(()=>{
    //play()
  },[])
  return (
    <section className="jigen_toys_section" id="JigenToysCon">
      <div className="jigen_toys_con">
        <div className="jigen_tit_content">
          <h5 className="nyathees_tit jigen_toys_tit"><span>O<span className="tit_orange">V</span>O</span><span className="nyathees_tit_x">×</span>Jigen Toys</h5>
        </div>
        <p className="jigen_toys_text">The OVO platform has been supported by Jigen Toys AR technology. The NFT assets of Nyathees' five star card will be upgraded to holographic AR assets!</p>
        <div className="jigen_toys_img">
          <div className="jigen_toys_index">
            <img className="jigen_toys_ban" src={require('../../../assets/images/index/jigentoys_img_bg.png')} alt="jigenToysBg" />
          </div>
          <div className="jigen_toys_positon" id="jigenToysBan" onClick={playBtn}>
            <img className="jigen_toys_ban" src={jigenToysImg} alt="jigenToys" />
            {/* <span className="paly_btn_con transition" onClick={playBtn}>
              <img className="paly_btn" src={playImg} />
            </span> */}
          </div>
          <video id="myVideo" className="jigen_toys_video" width="100%" height="100%" controls="controls">
            <source src={require('../../../assets/video/dance_03.mp4/dance_03.mp4')} type="video/mp4" />
            {/* <source src=".ogg" type="video/ogg" />
            <source src=".webm" type="video/dance_03.mp4" />
            <object data=".mp4" width="320" height="240">
              <embed src=".swf" width="320" height="240" />
            </object> */}
          </video>
        </div>
      </div>
      <div className="jigen_decorate_bg" id="jigenDecorateBg">
        <div className="jigen_decorate jigen_decorate0"></div>
        <div className="jigen_decorate jigen_decorate1"></div>
        <div className="jigen_decorate jigen_decorate2"></div>
        <div className="jigen_decorate jigen_decorate3"></div>
      </div>
    </section>
  )
}
export default JigenToys