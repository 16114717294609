import { useState,useEffect } from 'react';
const Roadmap = ()=>{
  return (
    <section className="roadmap_section" id="RoadmapCon">
      <h5 className="jigen_toys_tit roadmap_tit">Roadmap</h5>
      <div className="roadmap_content">
        <div className="roadmap_process_list roadmap_process_lg">
          <div className="roadmap_process_columns roadmap_process_columns0">
            <div className="roadmap_process_li roadmap_process_li0">
              <div className="process_li_con">
                <h5 className="roadmap_process_tit transition">OVO Platform 1.0</h5>
                <h6 className="roadmap_process_subtitle transition">DCIM System & App</h6>
                {/* 弹出层 */}
                <div className="roadmap_process_pop">
                  ① Construction of authoritative anti-counterfeiting mechanism<br/>
                  ② Decentralized transaction & auction & Mystery Box function<br/>
                  ③ Market PR strategy<br/>
                  ④ The construction of community incentive ecology
                </div>
              </div>
            </div>
            <div className="roadmap_process_li roadmap_process_li1">
              <div className="process_li_con">
                <h5 className="roadmap_process_tit transition">OVO Platform 1.5</h5>
                <h6 className="roadmap_process_subtitle transition">Mystery Box & Marketplace</h6>
                {/* 弹出层 */}
                <div className="roadmap_process_pop">
                  ① Flow/BSC cross chain development<br/>
                  ② Marketplace<br/>
                  ③ Nyathees Mystery Box I & II<br/>
                  ④ Gravure idols NFT Maindrop (FlowFest)
                </div>
              </div>
            </div>
            <div className="roadmap_process_li roadmap_process_li2">
              <div className="process_li_con">
                <h5 className="roadmap_process_tit transition">OVO Platform 2.0</h5>
                <h6 className="roadmap_process_subtitle transition">Fusion/Genesis/Picture Book</h6>
                {/* 弹出层 */}
                <div className="roadmap_process_pop">
                  ① NFT Fusion Function<br/>
                  ② NFT assets AR integration<br/>
                  ③ Picture Book Function<br/>
                  ④ COG Mystery Box
                </div>
              </div>
            </div>
          </div>
          <div className="roadmap_process_columns roadmap_process_columns1">
            <div className="roadmap_process_li roadmap_process_liy roadmap_process_li3">
              <div className="process_li_con">
                <h5 className="roadmap_process_tit transition">OVO Platform 3.0</h5>
                <h6 className="roadmap_process_subtitle transition">Launchpad & Token design</h6>
                {/* 弹出层 */}
                <div className="roadmap_process_pop">
                  ① OVO LaunchPad development<br/>
                  ② OVO Website UIUX upgrade<br/>
                  ③ COG system upgrade<br/>
                  ④ OVO Marketplace upgrade<br/>
                  ⑤ DCIM V2<br/>
                  ⑥ OVO Token tentative plans<br/>
                  ⑦ NFT-Mining tentative plans
                </div>
              </div>
            </div>
            <div className="roadmap_process_li roadmap_process_li4">
              <div className="process_li_con">
                <h5 className="roadmap_process_tit transition">OVO Platform 2.5</h5>
                <h6 className="roadmap_process_subtitle transition">BSC/Flow/ETH deployed</h6>
                {/* 弹出层 */}
                <div className="roadmap_process_pop">
                  ① OVO Metaartia plan<br/>
                  ② Genesis Function for COG<br/>
                  ③ ERC20 Chain deployment<br/>
                  ④ FLUcTUS -SYMBOL Sound ART Project<br/>
                  ⑤ Nyathees Mystery Box III development<br/>
                  ⑥ OVO Fusion and Picture Book (C2E) update<br/>
                  ⑦ Whitelist and freemint function
                </div>
              </div>
            </div>
          </div>
          <div className="roadmap_process_columns roadmap_process_columns2">
            <div className="roadmap_process_li roadmap_process_liy roadmap_process_li5">
              <div className="process_li_con">
                <h5 className="roadmap_process_tit transition">OVO Platform 3.5</h5>
                <h6 className="roadmap_process_subtitle transition">NFT-DAO</h6>
                {/* 弹出层 */}
                <div className="roadmap_process_pop">
                  ① Nyathees-DAO (Round4)<br/>
                  ② NFT Second Create Function
                </div>
              </div>
            </div>
          </div>
          <div className="roadmap_process_columns roadmap_process_columns3">
            <div className="roadmap_process_li roadmap_process_liy roadmap_process_li6">
              <div className="process_li_con">
                <h5 className="roadmap_process_tit transition">OVO Platform 4.0</h5>
                <h6 className="roadmap_process_subtitle transition">NFT-ID Soul-Bound Token</h6>
                {/* 弹出层 */}
                <div className="roadmap_process_pop">
                  ① Nyathees GameFi development<br/>
                  ② Multiverse development<br/>
                  ③ NFT-ID Soul-Bound Token development<br/>
                  ④ NFT cross-chain testnet (Hermes)
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="roadmap_process_list roadmap_process_sm">
          <div className="roadmap_process_columns roadmap_process_columns0">
            <div className="roadmap_process_li roadmap_process_li0">
              <div className="process_li_con">
                <h5 className="roadmap_process_tit transition">OVO Platform 1.0</h5>
                <h6 className="roadmap_process_subtitle transition">DCIM System & App</h6>
                {/* 弹出层 */}
                <div className="roadmap_process_pop">
                  ① Construction of authoritative anti-counterfeiting mechanism<br/>
                  ② Decentralized transaction & auction & Mystery Box function<br/>
                  ③ Market PR strategy<br/>
                  ④ The construction of community incentive ecology
                </div>
              </div>
            </div>
            <div className="roadmap_process_li roadmap_process_li1">
              <div className="process_li_con">
                <h5 className="roadmap_process_tit transition">OVO Platform 1.5</h5>
                <h6 className="roadmap_process_subtitle transition">Mystery Box & Marketplace</h6>
                {/* 弹出层 */}
                <div className="roadmap_process_pop">
                  ① Flow/BSC cross chain development<br/>
                  ② Marketplace<br/>
                  ③ Nyathees Mystery Box I & II<br/>
                  ④ Gravure idols NFT Maindrop (FlowFest)
                </div>
              </div>
            </div>
          </div>
          <div className="roadmap_process_columns roadmap_process_columns1">
            <div></div>
            <div className="roadmap_process_li roadmap_process_li2">
                <div className="process_li_con">
                  <h5 className="roadmap_process_tit transition">OVO Platform 2.0</h5>
                  <h6 className="roadmap_process_subtitle transition">Fusion/Genesis/Picture Book</h6>
                  {/* 弹出层 */}
                  <div className="roadmap_process_pop">
                    ① NFT Fusion Function<br/>
                    ② NFT assets AR integration<br/>
                    ③ Picture Book Function<br/>
                    ④ COG Mystery Box
                  </div>
                </div>
              </div>
          </div>
          <div className="roadmap_process_columns roadmap_process_columns2">
            <div></div>
            <div className="roadmap_process_li roadmap_process_li3">
              <div className="process_li_con">
                <h5 className="roadmap_process_tit transition">OVO Platform 2.5</h5>
                <h6 className="roadmap_process_subtitle transition">BSC/Flow/ETH deployed</h6>
                {/* 弹出层 */}
                <div className="roadmap_process_pop">
                  ① OVO Metaartia plan<br/>
                  ② Genesis Function for COG<br/>
                  ③ ERC20 Chain deployment<br/>
                  ④ FLUcTUS -SYMBOL Sound ART Project<br/>
                  ⑤ Nyathees Mystery Box III development<br/>
                  ⑥ OVO Fusion and Picture Book (C2E) update<br/>
                  ⑦ Whitelist and freemint function
                </div>
              </div>
            </div>
            <div className="roadmap_process_zs"></div>
          </div>
          <div className="roadmap_process_columns roadmap_process_columns3">
            <div></div>
            <div className="roadmap_process_li roadmap_process_liy roadmap_process_li4">
              <div className="process_li_con">
                <h5 className="roadmap_process_tit transition">OVO Platform 3.0</h5>
                <h6 className="roadmap_process_subtitle transition">Launchpad & Token design</h6>
                {/* 弹出层 */}
                <div className="roadmap_process_pop">
                  ① OVO LaunchPad development<br/>
                  ② OVO Website UIUX upgrade<br/>
                  ③ COG system upgrade<br/>
                  ④ OVO Marketplace upgrade<br/>
                  ⑤ DCIM V2<br/>
                  ⑥ OVO Token tentative plans<br/>
                  ⑦ NFT-Mining tentative plans
                </div>
              </div>
            </div>
          </div>
          <div className="roadmap_process_columns roadmap_process_columns4">
            <div></div>
            <div className="roadmap_process_li roadmap_process_liy roadmap_process_li5">
              <div className="process_li_con">
                <h5 className="roadmap_process_tit transition">OVO Platform 3.5</h5>
                <h6 className="roadmap_process_subtitle transition">NFT-DAO</h6>
                {/* 弹出层 */}
                <div className="roadmap_process_pop">
                  ① Nyathees-DAO (Round4)<br/>
                  ② NFT Second Create Function
                </div>
              </div>
            </div>
          </div>
          <div className="roadmap_process_columns roadmap_process_columns5">
            <div></div>
            <div className="roadmap_process_li roadmap_process_liy roadmap_process_li6">
              <div className="process_li_con">
                <h5 className="roadmap_process_tit transition">OVO Platform 4.0</h5>
                <h6 className="roadmap_process_subtitle transition">NFT-ID Soul-Bound Token</h6>
                {/* 弹出层 */}
                <div className="roadmap_process_pop">
                  ① Nyathees GameFi development<br/>
                  ② Multiverse development<br/>
                  ③ NFT-ID Soul-Bound Token development<br/>
                  ④ NFT cross-chain testnet (Hermes)
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Roadmap