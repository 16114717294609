import logo from "../../assets/images/ovoLogo.png"
import { useState,useEffect } from 'react';
const Header = ()=>{
  useEffect(()=>{

  },[])
  const NavClick = (id)=>{
    let IdConTop = 0
    if(id==='Home'){
      IdConTop = 0
    } else{
      IdConTop = document.getElementById(id).offsetTop
    }
    document.getElementById("parallaxB").scrollTop = IdConTop
    //scrollTopAnimate(IdConTop)
  }
  const scrollTopAnimate = (n)=>{
    var scrollTimer = setInterval(() => {
      let top = document.getElementById("parallaxB").scrollTop;
      let speed = n/4;
      if (top!=n) {
        if(top>n){
          top -= speed;
          document.getElementById("parallaxB").scrollTop -= speed
        }else{
          top += speed;
          console.log(top)
          document.getElementById("parallaxB").scrollTop = speed
        }
      }
      if (top == n) {
          clearInterval(scrollTimer);
      }
    }, 20);
  }
  return(
    <header className="header_con">
      <nav className="nav_list_con" id="NavList">
        <ul className="nav_list">
          <li onClick={()=>NavClick("DcimSec")}>
            <span className="transition">DCIM</span>
          </li>
          <li onClick={()=>NavClick("NyatheesSection")}>
            <span className="transition">COLLECTION</span>
          </li>
          <li onClick={()=>NavClick("JigenToysCon")}>
            <span className="transition">SuperAGC AR</span>
          </li>
          <li>
            <h1><span className="transition"><img className="logo_img" src={logo} /></span></h1>
          </li>
          <li onClick={()=>NavClick("MetaverseCon")}>
            <span className="transition">Metaverse</span>
          </li>
          <li onClick={()=>NavClick("RoadmapCon")}>
            <span className="transition">ROADMAP</span>
          </li>
          <li onClick={()=>NavClick("PartnerCon")}>
            <span className="transition">Partnership</span>
          </li>
        </ul>
      </nav>
      <nav className="nav_list_fix transition" id="NavListF">
        <ul className="nav_list">
          <li onClick={()=>NavClick("DcimSec")}>
            <span className="transition">DCIM</span>
          </li>
          <li onClick={()=>NavClick("NyatheesSection")}>
            <span className="transition">COLLECTION</span>
          </li>
          <li onClick={()=>NavClick("JigenToysCon")}>
            <span className="transition">SuperAGC AR</span>
          </li>
          <li onClick={()=>NavClick("Home")}>
            <h1><span className="transition"><img className="logo_img" src={logo} /></span></h1>
          </li>
          <li onClick={()=>NavClick("MetaverseCon")}>
            <span className="transition">Metaverse</span>
          </li>
          <li onClick={()=>NavClick("RoadmapCon")}>
            <span className="transition">ROADMAP</span>
          </li>
          <li onClick={()=>NavClick("PartnerCon")}>
            <span className="transition">Partnership</span>
          </li>
        </ul>
      </nav>
    </header>
  )
}
export default Header