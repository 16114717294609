import { useState,useEffect,useRef } from 'react';
import arrow0 from '../../../assets/images/index/arrow0.svg'
import SwipeableViews from 'react-swipeable-views';
import nyatheesImg0 from "../../../assets/images/index/nyathees_img0.png"
import nyatheesImg1 from "../../../assets/images/index/nyathees_img1.png"
import nyatheesImg2 from "../../../assets/images/index/nyathees_img2.png"
import nyatheesMImg0 from "../../../assets/images/mobileIndex/ip0.png"
import nyatheesMImg1 from "../../../assets/images/mobileIndex/ip1.png"
import nyatheesMImg2 from "../../../assets/images/mobileIndex/ip2.png"
const Nyathees = ({changeNs})=>{
  const [index,setIndex] = useState(1)
  // useEffect(()=>{
  //   //animationOpctity()
  // },[index])
  const changeIndex = (index)=>{
    // alert(index)
    setIndex(index)
  }
  const changeN = (type)=>{
    changeNs(type)
  }
  const styles = {
    root: {
      padding: '0 60px',
    },
    slideContainer: {
      padding: '0 20px',
    },
    slide: {
      // padding: 15,
      minHeight: 100,
      color: '#fff',
      textAlign:'center'
    },
    slide1: {
      backgroundColor: '#ffffff',
    },
    slide2: {
      backgroundColor: '#ffffff',
    },
    slide3: {
      backgroundColor: '#ffffff',
    },
  };
  return(
    <section className="nyathees_section" id="NyatheesSection">
      <div className="nyathees_content">
        {/* 手机端 */}
        <div className="nyathees_img_content">
          <div className="nyathees_img_box">
            <SwipeableViews className="swipe_nyathees_img" index={index} slideStyle={styles.slideContainer} enableMouseEvents onChangeIndex={changeIndex}>
              <div className="ny_slide_li" style={Object.assign({}, styles.slide, styles.slide1)}>
                <img src={nyatheesMImg0} />
              </div>
              <div className="ny_slide_li" style={Object.assign({}, styles.slide, styles.slide2)}>
                <img src={nyatheesMImg1} />
              </div>
              <div className="ny_slide_li" style={Object.assign({}, styles.slide, styles.slide3)}>
                <img src={nyatheesMImg2} />
              </div>
            </SwipeableViews>
          </div>
          <SwipeableViews className="swipe_nyathees_text" index={index} enableMouseEvents>
              <div className="nyathees_text_con">
                <h5 className="nyathees_tit"><span>O<span className="tit_orange">V</span>O</span><span className="nyathees_tit_x">×</span>COG</h5>
                <p className="nyathees_p">"Onigiriman" is a big name in the NFT art area that has become famous by continuing to produce illustrations of PopGirls. On OpenSea, the NFT of "Onigiriman" has reached 249 ETH. OVO has signed contracts with Onigiriman and “Cuty Owl Girls NFT ” will be released on OVO soon.</p>
              </div>
              <div className="nyathees_text_con">
                <h5 className="nyathees_tit"><span>O<span className="tit_orange">V</span>O</span><span className="nyathees_tit_x">×</span>Nyathees</h5>
                <p className="nyathees_p">Nyathees is cute but nasty, a very famous Anime character in Japan. The LINE stamp of Nyathees has got more than 4 million paid downloads. OVO has an exclusive contract with the creator of Nyathees and it has become one of the most famous NFT characters in Japan.</p>
              </div>
              <div className="nyathees_text_con">
                <h5 className="nyathees_tit"><span>O<span className="tit_orange">V</span>O</span><span className="nyathees_tit_x">×</span>Gravure</h5>
                <p className="nyathees_p">Gravure idol is a serious business in japan. Girls have more than 12 million fans all over the world. More than 17 Japanese Gravure idols have signed exclusive contracts with OVO. NFT of Gravure idols were released in 2021 FlowFest and sold out in 5 days, recording the 5th highest sales in Flowverse.</p>
              </div>
            </SwipeableViews>
        </div>
        <div className="nyathees_fl" id="nyatheesFl">
          <div className="nyathees_con_z" id="NyatheesTextCon">
            <div className="nyathees_text_con">
              <h5 className="nyathees_tit"><span>O<span className="tit_orange">V</span>O</span><span className="nyathees_tit_x">×</span>Nyathees</h5>
              <p className="nyathees_p">Nyathees is cute but nasty, a very famous Anime character in Japan. The LINE stamp of Nyathees has got more than 4 million paid downloads. OVO has an exclusive contract with the creator of Nyathees and it has become one of the most famous NFT characters in Japan.</p>
            </div>
            <div className="nyathees_text_con">
              <h5 className="nyathees_tit"><span>O<span className="tit_orange">V</span>O</span><span className="nyathees_tit_x">×</span>Gravure</h5>
              <p className="nyathees_p">Gravure idol is a serious business in japan. Girls have more than 12 million fans all over the world. More than 17 Japanese Gravure idols have signed exclusive contracts with OVO. NFT of Gravure idols were released in 2021 FlowFest and sold out in 5 days, recording the 5th highest sales in Flowverse.</p>
            </div>
            <div className="nyathees_text_con">
              <h5 className="nyathees_tit"><span>O<span className="tit_orange">V</span>O</span><span className="nyathees_tit_x">×</span>COG</h5>
              <p className="nyathees_p">"Onigiriman" is a big name in the NFT art area that has become famous by continuing to produce illustrations of PopGirls. On OpenSea, the NFT of "Onigiriman" has reached 249 ETH. OVO has signed contracts with Onigiriman and “Cuty Owl Girls NFT ” will be released on OVO soon.</p>
            </div>

          </div>
          <div className="nyathees_change_arrow">
            <span className="nyathees_change_fl nyathees_arrow" onClick={()=>changeN('0')}>
              <i className="iconfont transition icon-yuanjiantou1"></i>
            </span>
            <span className="nyathees_change_fr nyathees_arrow" onClick={()=>changeN('1')}>
              <i className="iconfont transition icon-yuanjiantou"></i>
            </span>
          </div>
        </div>
        <div className="nyathees_fr nyathees_img_con" id="NyatheesFr">
        {/* <!---> */}
          <img className="nyathees_img nyathees_img_animation" src={nyatheesImg0} alt="nyathees" />
          <img className="nyathees_img" src={nyatheesImg1} alt="nyathees" />
          <img className="nyathees_img" src={nyatheesImg2} alt="nyathees" />
        </div>
      </div>
    </section>
  )
}
export default Nyathees
